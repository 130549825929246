import NextLink from "next/link";
import { useRouter } from "next/router";

import {
  Box,
  CloseButton,
  Flex,
  Icon,
  Link,
  useBreakpointValue,
  useColorModeValue,
  type BoxProps,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

import Isotype from "~/components/Logo/Isotype";
import LogoNav from "~/components/Logo/LogoNav";

import MainNav from "./MainNav";
import PartyNav from "./PartyNav";

interface SidebarContentProps extends BoxProps {
  onClose: () => void;
  isSidebarOpen: boolean;
}

const SidebarContent = ({
  onClose,
  isSidebarOpen,
  ...rest
}: SidebarContentProps) => {
  const router = useRouter();
  const { partyId } = router.query;
  const widthSidebarAnimation = useBreakpointValue(
    {
      base: "100%",
      md: isSidebarOpen ? "240px" : "75px",
      lg: isSidebarOpen ? "320px" : "75px",
    },
    "lg",
  );
  return (
    <Box
      bg={useColorModeValue("white", "brand.nav")}
      borderRight={useColorModeValue(
        "1px solid rgb(232, 236, 253)",
        "1px solid rgb(40, 51, 66)",
      )}
      pos="fixed"
      h="full"
      overflowY={"auto"}
      {...rest}
    >
      <motion.div initial={false} animate={{ width: widthSidebarAnimation }}>
        <Flex
          h={{ base: 12, sm: 16 }}
          alignItems="center"
          mx={isSidebarOpen ? 6 : "auto"}
          justifyContent="space-between"
        >
          {!isSidebarOpen ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              marginLeft="auto"
              marginRight="auto"
            >
              <Link
                as={NextLink}
                href="/parties"
                _focus={{ boxShadow: "none" }}
              >
                <Icon as={Isotype} fontSize={36} color="#e717ad" />
              </Link>
            </Flex>
          ) : (
            <Flex
              display={{ base: "none", md: "flex" }}
              pt={1}
              alignItems="center"
              justifyContent="center"
              marginLeft="auto"
              marginRight="auto"
            >
              <Link
                as={NextLink}
                href="/parties"
                _focus={{ boxShadow: "none" }}
              >
                <LogoNav height={36} />
              </Link>
            </Flex>
          )}
          <CloseButton
            display={{ base: "flex", md: "none" }}
            ml="auto"
            onClick={onClose}
          />
        </Flex>
        {!partyId ? (
          <MainNav routerPath={router.asPath} isSidebarOpen={isSidebarOpen} />
        ) : (
          <PartyNav routerPath={router.asPath} isSidebarOpen={isSidebarOpen} />
        )}
      </motion.div>
    </Box>
  );
};

export default SidebarContent;

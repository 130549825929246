import { type FC } from "react";

import { useDisclosure, type ButtonProps } from "@chakra-ui/react";

import { Button } from "~/components/Buttons";
import { WithdrawModal } from "~/components/Modals";

import { usePartyContext } from "~/contexts/PartyContext";
import { usePlatformContext } from "~/contexts/PlatformContext";
import { usePartyBalance, usePartyWithdraw } from "~/hooks/party";
import { usePlatformNFT } from "~/hooks/platform";

import { type WithdrawForm } from "../types";

interface IWithdrawButton extends ButtonProps {
  partyAddress: string;
}

const WithdrawButton: FC<IWithdrawButton> = ({
  partyAddress,
  ...rest
}: IWithdrawButton) => {
  const {
    onClose: onCloseWithdraw,
    isOpen: isWithdrawOpen,
    onOpen: onOpenWithdraw,
  } = useDisclosure();
  const { isPunkHolder, PunkRequired } = usePlatformNFT();
  const { partyInfo } = usePartyContext();
  const { reloadRoles } = usePlatformContext();
  const {
    memberPartyBalance,
    reloadBalance,
    loading: loadingBalance,
  } = usePartyBalance(partyInfo?.address || "");
  const { withdrawFromParty, loadingWithdraw } = usePartyWithdraw(partyAddress);

  const handleWithdraw = async (withdrawForm: WithdrawForm) => {
    try {
      // Check if user holds a Punk
      const isHolder = await isPunkHolder();
      if (!isHolder) return;

      onCloseWithdraw();
      await withdrawFromParty(withdrawForm);
      void reloadRoles();
      void reloadBalance();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <PunkRequired />
      <Button onClick={onOpenWithdraw} isLoading={loadingWithdraw} {...rest}>
        Withdraw
      </Button>
      {partyInfo && (
        <WithdrawModal
          isWithdrawOpen={isWithdrawOpen}
          onCloseWithdraw={onCloseWithdraw}
          confirmAction={(values) => void handleWithdraw(values)}
          party={partyInfo}
          loadingBalance={loadingBalance}
          userPartyBalance={memberPartyBalance?.toString() || "0"}
        />
      )}
    </>
  );
};

export default WithdrawButton;

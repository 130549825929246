import { useRouter } from "next/router";

import { CheckIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";

import { getChainIconSVG, getChainName } from "~/utils/web3/chains";

import { usePartyContext } from "~/contexts/PartyContext";
import { supportedChains } from "~/contexts/WagmiProvider";

export default function SelectNetwork() {
  // Router
  const router = useRouter();
  // PartyContext
  const {
    chainId: desiredChainId,
    setChainState,
    routerChain,
    partyInfo,
  } = usePartyContext();

  const switchChain = (newChainId: number) => {
    // if we're already connected to the desired chain, return
    if (newChainId === desiredChainId) return;
    // if they want to connect to the default chain and we're already connected, return
    if (newChainId === -1 && desiredChainId !== undefined) return;
    console.log("switchChain to ", newChainId);
    if (!!routerChain) {
      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          chain: getChainName(newChainId, true),
        },
      });
    } else {
      if (window && window.localStorage) {
        window.localStorage.setItem("chainId", newChainId.toString());
      }
      setChainState({
        id: newChainId,
        name: getChainName(newChainId, true),
      });
    }
  };
  const desiredChainIconSVG = getChainIconSVG(desiredChainId);
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            borderRadius="lg"
            bg="none"
            _hover={{ bg: "gray.700" }}
            _focus={{ bg: "none" }}
            _active={{ bg: "gray.700" }}
            pl={1}
            pr={!partyInfo ? 1 : 3}
            py={1}
          >
            <Flex alignItems="center" gap={1}>
              {desiredChainIconSVG ? (
                <Avatar
                  icon={
                    <Icon as={desiredChainIconSVG} fontSize="24px" color="" />
                  }
                  size="sm"
                  bg="transparent"
                />
              ) : null}
              {getChainName(desiredChainId)}
              {!partyInfo && (
                <ChevronDownIcon
                  transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
                />
              )}
            </Flex>
          </MenuButton>
          <MenuList minWidth="200px" borderRadius="xl" zIndex={4} px={2}>
            <MenuOptionGroup
              value={desiredChainId.toString()}
              onChange={(_chainId) => switchChain(Number(_chainId))}
              type="radio"
            >
              {supportedChains.map((supportedChain) => {
                const supportedChainIconSVG = getChainIconSVG(
                  supportedChain.id,
                );
                return (
                  <MenuItemOption
                    key={supportedChain.id}
                    value={supportedChain.id.toString()}
                    icon={<></>}
                    iconSpacing="0px"
                    borderRadius="xl"
                  >
                    <Flex alignItems="center">
                      {supportedChainIconSVG ? (
                        <Avatar
                          icon={
                            <Icon
                              as={supportedChainIconSVG}
                              fontSize="24px"
                              color=""
                            />
                          }
                          size="sm"
                          bg="transparent"
                        />
                      ) : null}
                      <Text pl="8px">{supportedChain.name}</Text>
                      {desiredChainId === supportedChain.id && (
                        <CheckIcon ml="auto" fontSize="sm" color="brand.500" />
                      )}
                    </Flex>
                  </MenuItemOption>
                );
              })}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
}

import { useRouter } from "next/router";

import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { AiFillHome } from "react-icons/ai";
import { BiAddToQueue } from "react-icons/bi";
import { FiRepeat } from "react-icons/fi";
import { GiCrossedSwords, GiMonkey, GiVote } from "react-icons/gi";
import { IoIosWallet } from "react-icons/io";
import { RiSearchLine } from "react-icons/ri";

import { shortenHex } from "@partyfinance/core";

import { Jazzicon } from "~/components/Icons";

import { usePlatformContext } from "~/contexts/PlatformContext";

import NavItem, { type NavItemProps } from "./NavItem";

const AuthNavItems: Array<NavItemProps> = [
  { path: "/parties", name: "Home", icon: AiFillHome },
  { path: "/parties/explore", name: "Explore parties", icon: RiSearchLine },
  { path: "/partywars", name: "Party Wars", icon: GiCrossedSwords },
  { path: "/parties/create", name: "Create a party", icon: BiAddToQueue },
  {
    path: "/partyapes",
    name: "PartyApes",
    icon: GiMonkey,
  },
  {
    path: "/vote",
    name: "Ape Council",
    icon: GiVote,
  },
  {
    path: "/trade",
    name: "Trade",
    icon: FiRepeat,
  },
];

interface MainNavProps {
  isSidebarOpen: boolean;
  routerPath: string;
}

const MainNav = ({ routerPath, isSidebarOpen }: MainNavProps) => {
  const { profile } = usePlatformContext();
  const router = useRouter();
  return (
    <>
      <Box
        px={0}
        py={4}
        width="fit-content"
        marginLeft="auto"
        marginRight="auto"
      >
        <>
          <Box
            width="fit-content"
            marginLeft="auto"
            marginRight="auto"
            marginBottom={isSidebarOpen ? 4 : 0}
          >
            <Tooltip
              // isDisabled={isSidebarOpen}
              hasArrow
              placement={isSidebarOpen ? "auto" : "right"}
              label={"Show Profile"}
              shouldWrapChildren={false}
            >
              <Avatar
                icon={
                  !!profile && profile.picture === null ? (
                    <Flex borderRadius="50%" overflow="hidden">
                      <Jazzicon
                        account={profile.address}
                        diameter={isSidebarOpen ? 96 : 48}
                      />
                    </Flex>
                  ) : undefined
                }
                bg="gray.500"
                src={!profile || !profile.picture ? "" : profile.picture}
                size={isSidebarOpen ? "xl" : "md"}
                cursor={profile ? "pointer" : "initial"}
                onClick={profile ? () => router.push("/profile") : undefined}
              >
                <AvatarBadge
                  boxSize="0.8em"
                  bg={profile?.address ? "green.500" : "gray.500"}
                />
              </Avatar>
            </Tooltip>
          </Box>

          {isSidebarOpen && (
            <Box>
              <Text
                fontSize="lg"
                textAlign="center"
                textOverflow="ellipsis"
                maxWidth="200px"
              >
                {!profile ? "" : profile.name || "Anonymous"}
              </Text>
              <Flex marginTop={2} gap={2}>
                <Icon
                  as={IoIosWallet}
                  fontSize="22"
                  marginTop="auto"
                  marginBottom="auto"
                />
                <Text color="gray.600" fontSize={"md"}>
                  {profile ? shortenHex(profile.address, 4) : "Not connected"}
                </Text>
              </Flex>
            </Box>
          )}
        </>
      </Box>
      <Flex
        direction="column"
        gap={1}
        px={
          isSidebarOpen ? { base: 2, md: 4, lg: 6 } : { base: 1, md: 2, lg: 3 }
        }
      >
        {AuthNavItems.map((item) => (
          <NavItem
            key={item.name}
            isSidebarOpen={isSidebarOpen}
            name={item.name}
            path={item.path}
            icon={item.icon}
            disabled={item.disabled}
            routerPath={routerPath}
          />
        ))}
      </Flex>
    </>
  );
};

export default MainNav;

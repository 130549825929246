import { type ReactNode } from "react";

import {
  Box,
  Drawer,
  DrawerContent,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

import SidebarContent from "./components/SidebarContent";

interface SidebarProps {
  isOpen: boolean;
  isSidebarOpen: boolean;
  onOpen?: () => void;
  onClose: () => void;
  children: ReactNode;
}

const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  onClose,
  isSidebarOpen,
  children,
}) => {
  const widthLayoutAnimation = useBreakpointValue(
    {
      base: "100%",
      md: isSidebarOpen ? "calc(100% - 240px)" : "calc(100% - 75px)",
      lg: isSidebarOpen ? "calc(100% - 320px)" : "calc(100% - 75px)",
    },
    "lg",
  );
  return (
    <Box bg={useColorModeValue("gray.100", "brand.main")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        isSidebarOpen={isSidebarOpen}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent w="full" onClose={onClose} isSidebarOpen={true} />
        </DrawerContent>
      </Drawer>
      <motion.div
        initial={false}
        animate={{
          width: widthLayoutAnimation,
        }}
        style={{ marginLeft: "auto" }}
      >
        {children}
      </motion.div>
    </Box>
  );
};

export default Sidebar;

import { useState, type FC } from "react";

// import ConnectWallet from '~/modules/web3/components/ConnectWallet'
import dynamic from "next/dynamic";
import NextLink from "next/link";

import {
  Divider,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { type IconType } from "react-icons";
import {
  FiInbox,
  FiMessageCircle,
  FiMoon,
  FiPower,
  FiSettings,
  FiShoppingBag,
  FiSun,
  FiUser,
} from "react-icons/fi";
import { useDisconnect } from "wagmi";

import { usePlatformContext } from "~/contexts/PlatformContext";
import SelectNetwork from "~/modules/web3/components/SelectNetwork";

const ConnectWallet = dynamic(
  () => import("~/modules/web3/components/ConnectWallet"),
  { ssr: false },
);

interface IOptionButton {
  title: string;
  icon: IconType;
  iconColor?: string;
  isExternal?: boolean;
  onClick?: () => void;
}

const OptionButton: FC<IOptionButton> = ({
  title,
  icon,
  iconColor,
  onClick = () => null,
}) => (
  <MenuItem py={2} onClick={onClick}>
    <HStack alignItems="center" width="full">
      <Icon as={icon} color={iconColor} fontSize="sm" />
      <Text>{title}</Text>
    </HStack>
  </MenuItem>
);

export default function SettingsButton() {
  const { profile } = usePlatformContext();
  const { disconnect } = useDisconnect();
  const { colorMode, toggleColorMode } = useColorMode();
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(!isOpen);
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        _focus={{
          outline: "none",
        }}
        outline="none"
        variant="ghost"
        aria-label="open menu"
        icon={<FiSettings />}
        borderRadius="lg"
        onClick={open}
      />
      <MenuList
        w="185px"
        borderRadius="xl"
        borderWidth={useColorModeValue(1, 0)}
        zIndex={4}
      >
        <VStack display={{ base: "flex", md: "none" }} gap={2} pt={2} pb={4}>
          <SelectNetwork />
          <Divider />
          <ConnectWallet />
          <Divider />
        </VStack>
        <VStack alignItems="stretch">
          <Link as={NextLink} href="/profile">
            <OptionButton
              title={!!profile ? "Profile" : "Create profile"}
              icon={FiUser}
              isExternal
            />
          </Link>
          <Link href="https://discord.gg/wSbbHS4E5E" isExternal>
            <OptionButton title="Discord" icon={FiMessageCircle} />
          </Link>
          <Link
            href="https://quickswap.exchange/#/swap?outputCurrency=0xe46B4A950c389e80621d10Dfc398e91613C7e25E"
            isExternal
          >
            <OptionButton title="Buy pFi" icon={FiShoppingBag} />
          </Link>
          <Link
            href="https://roadmap.party.finance/feature-requests"
            isExternal
          >
            <OptionButton title="Feature Requests" icon={FiInbox} />
          </Link>
          <OptionButton
            title={
              colorMode === "dark"
                ? "Switch to Light Mode"
                : "Switch to Dark Mode"
            }
            icon={colorMode === "dark" ? FiSun : FiMoon}
            onClick={toggleColorMode}
            iconColor={colorMode === "dark" ? "#ffb800" : ""}
          />
          {!!profile && (
            <OptionButton
              title={"Disconnect"}
              icon={FiPower}
              onClick={disconnect}
            />
          )}
        </VStack>
      </MenuList>
    </Menu>
  );
}

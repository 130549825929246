import { type FC } from "react";

import { useDisclosure, type ButtonProps } from "@chakra-ui/react";

import { Button } from "~/components/Buttons";
import { DepositModal } from "~/components/Modals";

import { usePartyContext } from "~/contexts/PartyContext";
import { usePartyDeposit } from "~/hooks/party";
import { usePlatformNFT } from "~/hooks/platform";
import { usePartyInfo } from "~/hooks/theGraph";

interface IDepositButton extends ButtonProps {
  partyAddress: string;
}

const DepositButton: FC<IDepositButton> = ({
  partyAddress,
  ...rest
}: IDepositButton) => {
  const {
    onClose: onCloseDeposit,
    isOpen: isDepositOpen,
    onOpen: onOpenDeposit,
  } = useDisclosure();
  const { isPunkHolder, PunkRequired } = usePlatformNFT();
  const { chainId } = usePartyContext();
  const { partyInfo, loading } = usePartyInfo(partyAddress, chainId);
  const { depositToParty, loadingDeposit } = usePartyDeposit(partyAddress);

  const handleDeposit = async (amount: number, allocate: boolean) => {
    try {
      // Check if user holds a Punk
      const isHolder = await isPunkHolder();
      if (!isHolder) return;

      onCloseDeposit();
      await depositToParty(amount, allocate);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <PunkRequired />
      <Button
        onClick={onOpenDeposit}
        isLoading={loading || loadingDeposit}
        {...rest}
      >
        Deposit
      </Button>
      {partyInfo && (
        <DepositModal
          isOpen={isDepositOpen}
          onClose={onCloseDeposit}
          confirmAction={handleDeposit}
          party={partyInfo}
        />
      )}
    </>
  );
};

export default DepositButton;

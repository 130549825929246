import NextLink from "next/link";
import { useRouter } from "next/router";

import {
  Avatar,
  Box,
  Divider,
  Flex,
  Heading,
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsMegaphone } from "react-icons/bs";
import {
  FiActivity,
  FiArrowLeftCircle,
  FiBriefcase,
  FiInfo,
  FiMessageCircle,
  FiRepeat,
  FiSettings,
  FiUsers,
} from "react-icons/fi";
import { GiBalloons } from "react-icons/gi";
import { RiTableAltLine, RiUserSettingsLine } from "react-icons/ri";

import { InviteModal, InviteModalLocationEnum } from "~/components/Modals";

import { usePartyContext } from "~/contexts/PartyContext";
import { usePlatformContext } from "~/contexts/PlatformContext";
import DepositButton from "~/modules/party/components/DepositButton";
import JoinPartyButton from "~/modules/party/components/JoinPartyButton";
import WithdrawButton from "~/modules/party/components/WithdrawButton";

import NavItem, { type NavItemProps } from "./NavItem";

interface PartyNavProps {
  isSidebarOpen: boolean;
  routerPath: string;
  disabled?: boolean;
}

const PartyNav = ({ routerPath, isSidebarOpen }: PartyNavProps) => {
  const router = useRouter();
  const { partyId } = router.query;
  const { partyRoles } = usePlatformContext();
  const { isParty, partyInfo, isIndexParty } = usePartyContext();
  const isOwner = partyRoles.isCreator;
  const isMember = partyRoles.isMember;
  const isManager = partyRoles.isManager;

  const AuthPartyNavItems: Array<NavItemProps> = [
    {
      path: "/parties/[chain]/[partyId]",
      name: "Details",
      icon: FiInfo,
    },
    {
      path: "/parties/[chain]/[partyId]/portfolio",
      name: "Portfolio",
      icon: FiBriefcase,
    },
    {
      path: "/parties/[chain]/[partyId]/trade",
      name: "Trade",
      icon: FiRepeat,
      authorized: isOwner || isManager,
    },
    {
      path: "/parties/[chain]/[partyId]/members",
      name: "Members",
      icon: FiUsers,
      authorized: isOwner || isManager,
    },
    {
      path: "/parties/[chain]/[partyId]/transactions",
      name: "Transactions",
      icon: RiTableAltLine,
    },
    {
      path: "/parties/[chain]/[partyId]/announcements",
      name: "Announcements",
      icon: BsMegaphone,
    },
    {
      path: "/parties/[chain]/[partyId]/activity",
      name: "Activity",
      icon: FiActivity,
    },
    {
      path: "/parties/[chain]/[partyId]/chat",
      name: "Chat",
      icon: FiMessageCircle,
      authorized: isMember,
    },
    {
      path: "/parties/[chain]/[partyId]/settings",
      name: "Settings",
      icon: FiSettings,
      authorized: isOwner || isManager,
    },
  ];
  const AuthIndexPartyNavItems: Array<NavItemProps> = [
    {
      path: "/index/[chain]/[partyId]",
      name: "Details",
      icon: FiInfo,
    },
    {
      path: "/index/[chain]/[partyId]/portfolio",
      name: "Portfolio",
      icon: FiBriefcase,
    },
    {
      path: "/index/[chain]/[partyId]/trade",
      name: "Trade",
      icon: FiRepeat,
      authorized: isOwner || isManager,
    },
    {
      path: "/index/[chain]/[partyId]/members",
      name: "Members",
      icon: FiUsers,
      authorized: isOwner || isManager,
    },
    {
      path: "/index/[chain]/[partyId]/management",
      name: "Managers",
      icon: RiUserSettingsLine,
      authorized: isOwner,
    },
    {
      path: "/index/[chain]/[partyId]/transactions",
      name: "Transactions",
      icon: RiTableAltLine,
    },
    {
      path: "/index/[chain]/[partyId]/activity",
      name: "Activity",
      icon: FiActivity,
    },
    {
      path: "/index/[chain]/[partyId]/chat",
      name: "Chat",
      icon: FiMessageCircle,
      authorized: isMember,
    },
  ];
  const inactiveBgHover = useColorModeValue("gray.50", "#303235");
  const arrowBackColor = useColorModeValue("white", "brand.main");

  return (
    <>
      {partyInfo && (
        <>
          <Tooltip
            isDisabled={isSidebarOpen}
            hasArrow
            placement="right"
            label={partyInfo.name}
            shouldWrapChildren={false}
          >
            <Flex
              pt={{ base: 0, sm: 4 }}
              pb={4}
              px={4}
              justifyContent="center"
              alignItems="center"
              gap={3}
            >
              <Avatar
                src={partyInfo.img}
                bgGradient="linear(to-b, brand.300, brand.500)"
                icon={<Icon as={GiBalloons} fontSize="22px" color="white" />}
                size={isSidebarOpen ? "lg" : "md"}
              />
              {isSidebarOpen && <Heading size="md">{partyInfo.name}</Heading>}
            </Flex>
          </Tooltip>
          {isMember || isOwner ? (
            <Flex
              justifyContent="center"
              alignItems="center"
              gap={0}
              pb={{ base: 4, sm: isSidebarOpen ? 3 : 0 }}
              height={isSidebarOpen ? "initial" : 0}
              visibility={isSidebarOpen ? "visible" : "hidden"}
            >
              {!partyInfo.isClosed && (
                <DepositButton
                  partyAddress={partyInfo.address}
                  width={{ base: 28, sm: 32, md: 24, lg: 32 }}
                  borderRadius={0}
                  borderBottomLeftRadius={"xl"}
                  borderTopLeftRadius={"xl"}
                  borderRightColor="brand.nav"
                  borderRightWidth={1}
                />
              )}
              <WithdrawButton
                partyAddress={partyInfo.address}
                width={{ base: 28, sm: 32, md: 24, lg: 32 }}
                borderRadius="xl"
                borderBottomLeftRadius={partyInfo.isClosed ? "xl" : 0}
                borderTopLeftRadius={partyInfo.isClosed ? "xl" : 0}
              />
            </Flex>
          ) : !isMember && !isOwner && !partyInfo.isClosed ? (
            <Flex
              justifyContent="center"
              alignItems="center"
              gap={4}
              pb={4}
              height={isSidebarOpen ? "initial" : 0}
              visibility={isSidebarOpen ? "visible" : "hidden"}
            >
              <JoinPartyButton partyAddress={partyInfo.address} />
            </Flex>
          ) : null}

          <Divider display={{ base: "block", sm: "none" }} />
        </>
      )}
      <LinkBox
        cursor="pointer"
        display={{ base: "block", sm: !partyInfo ? "block" : "none" }}
      >
        <Tooltip
          isDisabled={isSidebarOpen}
          hasArrow
          placement="right"
          label="Back to Parties"
          shouldWrapChildren={false}
        >
          <Box>
            <LinkOverlay as={NextLink} href="/parties">
              <Flex
                py={8}
                px={6}
                justifyContent="start"
                alignItems="center"
                _hover={{ bg: inactiveBgHover }}
              >
                <Icon
                  as={FiArrowLeftCircle}
                  color={arrowBackColor}
                  fill="#eb238e"
                  fontSize="24px"
                />
                {isSidebarOpen && (
                  <Text fontWeight={600} ml={4}>
                    Back to Parties
                  </Text>
                )}
              </Flex>
            </LinkOverlay>
          </Box>
        </Tooltip>
      </LinkBox>
      {isParty && (
        <>
          <Divider />
          <Flex
            direction="column"
            mt={3}
            gap={1}
            px={
              isSidebarOpen
                ? { base: 2, md: 4, lg: 6 }
                : { base: 1, md: 2, lg: 3 }
            }
            pb={4}
          >
            {(isIndexParty ? AuthIndexPartyNavItems : AuthPartyNavItems).map(
              (item) => {
                const showItem =
                  item.authorized || item.authorized === undefined;
                if (!showItem) {
                  return null;
                } else {
                  return (
                    <NavItem
                      key={item.name}
                      isSidebarOpen={isSidebarOpen}
                      name={item.name}
                      path={item.path}
                      icon={item.icon}
                      routerPath={routerPath}
                      modal={item.modal}
                      disabled={item.disabled}
                    />
                  );
                }
              },
            )}
            <InviteModal
              location={InviteModalLocationEnum.Navbar}
              address={partyId ? partyId.toString() : ""}
              isSidebarOpen={isSidebarOpen}
            />
          </Flex>
        </>
      )}
    </>
  );
};

export default PartyNav;

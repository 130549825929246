// import ConnectWallet from '~/modules/web3/components/ConnectWallet'
import dynamic from "next/dynamic";
import NextLink from "next/link";

import {
  Flex,
  HStack,
  IconButton,
  Link,
  useColorModeValue,
  type FlexProps,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";

import LogoNav from "~/components/Logo/LogoNav";

import SettingsButton from "~/modules/common/components/SettingsButton";
import SelectNetwork from "~/modules/web3/components/SelectNetwork";

const ConnectWallet = dynamic(
  () => import("~/modules/web3/components/ConnectWallet"),
  { ssr: false },
);

interface HeaderProps extends FlexProps {
  onOpen: () => void;
}
const Header = ({ onOpen }: HeaderProps) => {
  return (
    <Flex
      px={{ base: 4, md: 4 }}
      height={16}
      alignItems="center"
      bg={useColorModeValue("white", "")}
      justifyContent={{ base: "space-between" }}
      borderBottom={useColorModeValue(
        "1px solid rgb(232, 236, 253)",
        "1px solid rgb(40, 51, 66)",
      )}
    >
      <IconButton
        _focus={{
          outline: "none",
        }}
        display={{ base: "flex", sm: "flex", md: "flex" }}
        outline="none"
        variant="ghost"
        aria-label="open menu"
        icon={<FiMenu />}
        borderRadius="lg"
        onClick={onOpen}
      />

      <Link
        as={NextLink}
        href="/parties"
        display={{ base: "flex", sm: "none", md: "none" }}
      >
        <LogoNav width={160} />
      </Link>

      <Link
        as={NextLink}
        href="/parties"
        display={{ base: "none", sm: "flex", md: "none" }}
      >
        <LogoNav width={200} />
      </Link>

      <HStack spacing={{ base: "3", md: "4" }}>
        <Flex display={{ base: "none", md: "flex" }}>
          <SelectNetwork />
        </Flex>
        <Flex display={{ base: "none", md: "flex" }}>
          <ConnectWallet />
        </Flex>
        <SettingsButton />
      </HStack>
    </Flex>
  );
};

export default Header;

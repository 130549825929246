import { type FC } from "react";

import { useBreakpointValue } from "@chakra-ui/react";
import { useAccount } from "wagmi";

import { Button } from "~/components/Buttons";

import { usePlatformContext } from "~/contexts/PlatformContext";
import {
  usePartyGating,
  usePartyJoin,
  usePartyJoinAllowance,
} from "~/hooks/party";
import { usePlatformCheck, usePlatformNFT } from "~/hooks/platform";

interface IJoinPartyButton {
  partyAddress: string;
}

const JoinPartyButton: FC<IJoinPartyButton> = ({ partyAddress }) => {
  const { reloadRoles } = usePlatformContext();
  const { isPunkHolder, PunkRequired } = usePlatformNFT();
  const { hasGateAccess, GatesRequired, checkingAccess } = usePartyGating();
  const { address: account } = useAccount();
  const { checkWallet } = usePlatformCheck();
  const { partyJoinAllowance, reload, loading } = usePartyJoinAllowance(
    partyAddress,
    account || "",
  );
  const { joinParty, loadingJoin, JoinDepositModalRequired } =
    usePartyJoin(partyAddress);

  const handleJoinParty = async () => {
    try {
      if (!checkWallet()) return;
      // Check if user holds a Punk
      const isHolder = await isPunkHolder();
      if (!isHolder) return;
      const isAuthorized = await hasGateAccess();
      if (!isAuthorized) return;

      await joinParty();
      await reloadRoles();
      await reload();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return (
    <>
      <PunkRequired />
      <GatesRequired />
      <Button
        my="auto"
        px={6}
        type="submit"
        onClick={() => void handleJoinParty()}
        isLoading={loadingJoin || loading || checkingAccess}
      >
        {useBreakpointValue(
          !partyJoinAllowance ||
            !partyJoinAllowance.isPrivate ||
            partyJoinAllowance.isAllowed
            ? {
                base: "Join",
                sm: "Join Party",
              }
            : partyJoinAllowance.hasRequested
            ? {
                base: "Pending request",
                sm: "Join request pending",
              }
            : {
                base: "Request",
                sm: "Request to join",
              },
          "sm",
        )}
      </Button>
      <JoinDepositModalRequired />
    </>
  );
};

export default JoinPartyButton;

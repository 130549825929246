import { useEffect, type FC, type ReactNode } from "react";

import {
  Box,
  Flex,
  useBoolean,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import { Header } from "~/components/Headers";
import SEO from "~/components/SEO/SEO";
import { Sidebar } from "~/components/Sidebars";

interface LayoutProps {
  title?: string;
  description?: string;
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ title, children, description }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSidebarOpen, toggleSidebar] = useBoolean(true);

  useEffect(() => {
    if (window && window.localStorage) {
      const _collapsed = window.localStorage.getItem("collapsed");
      if (_collapsed && _collapsed === "1") {
        toggleSidebar.off();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window && window.localStorage) {
      window.localStorage.setItem("collapsed", isSidebarOpen ? "0" : "1");
    }
  }, [isSidebarOpen]);

  const handleOpen = useBreakpointValue({
    base: onOpen,
    md: toggleSidebar.toggle,
  });
  return (
    <>
      <SEO title={title} socialPreviewDescription={description} />
      <Sidebar
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        isSidebarOpen={isSidebarOpen}
      >
        <Box
          bg={useColorModeValue(
            "radial-gradient(153.32% 100% at 47.26% 0%, rgb(229 71 196 / 6%) 0%, rgb(255 0 221 / 3%) 48.19%, rgb(228 39 178 / 1%) 100%),rgb(255, 255, 255)",
            "brand.main",
          )}
        >
          <Header onOpen={() => (handleOpen ? handleOpen() : null)} />
          <Flex grow={1} flexWrap="wrap">
            {children}
          </Flex>
        </Box>
      </Sidebar>
    </>
  );
};

export default Layout;

import { memo } from "react";

import Head from "next/head";

import { NextSeo } from "next-seo";

interface ISEO {
  title?: string;
  description?: string;
  socialPreviewDescription?: string;
  socialPreviewImage?: string;
}

const SEO = ({
  title = "PartyFinance - Party up! Trade Defi!",
  description = "Join the party! Trade Defi as a group, curate a blue chip fractionalised NFT collection, raise money for charity and much more!",
  socialPreviewDescription = "Join the party and connect with others to trade collectively.",
  socialPreviewImage = "https://assets.party.finance/partyfinance_preview.png",
}: ISEO) => {
  return (
    <>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="shortcut icon" type="image/png" href="/favicon.png" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
          key="viewport"
        />
      </Head>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          title,
          description: socialPreviewDescription,
          images: [{ url: socialPreviewImage }],
          site_name: "PartyFinance",
        }}
        twitter={{
          handle: "@party_fi",
          site: "@party_fi",
          cardType: "summary_large_image",
        }}
      />
    </>
  );
};

export default memo(SEO);
